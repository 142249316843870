.App {
	margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.link-do-menu {
  text-align: right;
  margin-top: 0px;
  padding-top: 0px;
}

.hide {
  display: none;
}

.dropdown-menu {
  z-index: 1051;
}

.spinner {
    display: block;
    position: fixed;
    z-index: 1031; /* High z-index so it is on top of the page */
    top: calc( 50% - ( 45px / 2) ); /* where ... is the element's height */
    right: calc( 50% - ( 45px / 2) ); /* where ... is the element's width */
}