.about a {
    color: #ebb4ad;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
}

.about h1 {
    color: #354f45;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
}
