@media all and (min-width: 300px) {
	.Signup {
		padding: 30px 0;
	}

	.Signup form {
		margin: 0 auto;
		max-width: 320px;
	}

	.Signup form span.help-block {
		font-size: 14px;
		padding-bottom: 10px;
		color: #999;
	}

	.Signup h2 {
		font-weight: bold;
		font-size: 1.2em;
	}
}
