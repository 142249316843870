.mySaleContainer h2 {
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.mySaleContainer p {
  margin-left: 10px;
  margin-right: 10px;
}

.mySaleFormulario {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}
