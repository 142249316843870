.myReferralDetail__label {
  padding-top: 5px;
  padding-left: 5px;
  font-size: 0.8em;
  color:#543440;
}

.myReferralDetail__info {
  font-size: 1.0em;
  font-weight: bold;
  background-color: #f8e9e4;
  color:#543440;
  padding: 5px;
}

.myReferralDetail__product {
  max-width: 80%;
  display: inline-block;
}

.myReferralDetail__productimg {
  width: 100%;
  text-align: center;
}
