@media all and (min-width: 300px) {
	.Login {
		padding: 10px 0;
		margin: 10px 10px 10px 10px;
	}

	.Login form {
		margin: 0 auto;
		max-width: 320px;
	}

	.Login h2 {
		font-weight: bold;
		font-size: 1.2em;
	}

	.Login p {
		text-align: center;
		margin-top:10px;
	}
}
