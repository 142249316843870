.myReferralDiscount__label--qr {
  margin: auto;
  text-align: center;
  padding-top: 5px;
  font-size: 1.0em;
  color:#543440;
}

.myReferralDiscount__qrcode {
    margin: auto;
    margin-top: -10px;
    width: 130px;
    padding: 10px;
}

.myReferralDiscount__qrcode--utilizado {
    margin: auto;
    width: 130px;
    padding: 10px;
    color: #543440;
    display: none;
}
