.NewPayment {
    padding: 10px;
    padding-right: 10 px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100%-20px);
    background-color: white;
}

.NewPayment-Button {
    width: 100%;
    padding: 5px;
    display: inline;
    float: left;
}

.fifity {
    width:42%;
    display: inline;
    float: left;
    margin: 0px 0px 0px 10px;
    height: 40px;
}

.form-check label:nth-child(odd) {
    background-color: #f8e9e4;
}