.MySwiper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.legenda {
  color: #ebb4ad;
  background-color: #ffffff;
  font-family: Quicksand , sans-serif;
  font-weight: 600;
  height: 50px;
  bottom: 50px;
  left: 0px;
  }

.prod {
  max-width: 100%;
  max-height: calc(100vh - 180px);
  width: auto;
  height: auto;
  overflow: hidden;
}

span {
  font-family: Quicksand , sans-serif;
  font-weight: 600;
}

  #logomarca {
  margin: 0 15px;
}

.formindicacao {
  width: 100%;
  padding: 10px 0 10px 0;
  font-size: 1.2em;
  text-align:center;
}

#nmCliente {
  display: none;
}

#divReferral{
  display:block;
  color: #ebb4ad;
  background-color: #ffffff;
  font-family: Quicksand , sans-serif;
  font-weight: 600;
  height: auto;
  top: 56px;
  left: 0px;
}

#btEnviar {
  background: #543440;
  color: #fff;
}

.myswipermodal {
  display: grid;
  height: 100%;
  left: 0;
  position: relative;
  width: 100%;
}

.myswipermodal.open {
  display: grid;
  grid-template-rows: auto 100px 100px;
}

.modal_content {
  overflow-y: auto;
}

.modal_footer {
  background-color: #ffffff;
}