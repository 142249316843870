.Home .lander {
	padding: 0px 0;
	text-align: center;
}

.Home .lander h1 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}

.Home .lander p {
	color: #999;
}
