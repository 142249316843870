
.Payment {
    padding: 10px 10px;
}

.PaymentFiltro {
    padding: 1px 1px;
    margin: 1px 1px;
    width: 100%;
}

input.PaymentFiltro {
    width: 10px;
}

.PaymentBlock {
    margin: 0px;
    padding: 0px;
}
label {
    margin: 0px 0px 0px 0px;
    padding: 0px;
}

.PaymentLinha {
    display: inline; 
    float:left;
    padding: 0px 0px;
    width: 50%;
}

.PaymentBloco input {
    width: calc(100%-10px);
    margin: 0px 0px 0px 0px;
}

.PaymentTabela {
    margin: 70px 0px;
}

.PaymentTabela tr:nth-child(odd) {
    background-color: #f8e9e4;
}

.PaymentTotal {
    color:black;
    background-color: white;
    border: none;
    font-weight: bold; 
}